













import Vue from "vue";
// TODO: Need to add source location from state management
export default Vue.extend({
  name: "get-direction-btn",
  props: {
    block: { type: Boolean, default: false },
    destination: { type: Object, required: true },
    squared: { type: Boolean, default: false },
  },
  methods: {
    goToAddr() {
      const url = `http://maps.google.com/?daddr=${this.destination.lat}, ${this.destination.lng}`;
      const target = "_blank";
      window.open(url, target);
    },
  },
});
