
























import Vue from "vue";
import BtnFav from "@/components/BtnFav.vue";
import BtnShare from "@/components/BtnShare.vue";
import { formatPrice } from "../../utils";
export default Vue.extend({
  name: "price-loaction-card",
  components: {
    "btn-fav": BtnFav,
    "btn-share": BtnShare
  },
  props: {
    title: { type: String, required: true },
    price: { type: String, required: true },
    location: { type: String, required: true },
    postdate: { type: String, required: true },
    details: { type: String }
  },
  methods: {
    formatPrice: formatPrice,
    dateFormat(date: string) {
      const d = new Date(date);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      return `${d.getDate()}-${months[d.getMonth()]}-${d.getFullYear()}`;
    }
  }
});
