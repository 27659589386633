















import Vue from "vue";
export default Vue.extend({
  props: {
    images: { type: Array }
  },
  methods: {
    goTo(index: number) {
      //@ts-ignore
      this.$refs.myCarousel.setSlide(index);
    }
  }
});
