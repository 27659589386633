
























import Vue from "vue";
export default Vue.extend({
  props: {
    desc: { type: String, required: true },
    details: { type: Boolean }
  }
});
