






import Vue from "vue";

export default Vue.extend({
  name: "price-loaction-card",
  components: {},
  props: {
    squared: { type: Boolean, default: false },
    selleId: { type: String, required: true }
  },
  computed: {
    userProfile() {
      //@ts-ignore
      return `/users/${this.$props.selleId}`;
    }
  }
});
