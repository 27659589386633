











































import Vue from "vue";
import Carousel from "@/components/desktop/Carousel.vue";
import ItemDescCard from "@/components/desktop/ItemDescCard.vue";
import PriceLocationCard from "@/components/desktop/PriceLocationCard.vue";
import SellerDetails from "@/components/desktop/SellerDetails.vue";
import GetDirectionBtn from "@/components/GetDirectionBtn.vue";
import Header from "@/views/Header.vue";
import { Item } from "../types";
import http from "@/utils/axios";

export default Vue.extend({
  name: "item",
  components: {
    "header-top": Header,
    carousel: Carousel,
    "item-desc-card": ItemDescCard,
    "price-location-card": PriceLocationCard,
    "seller-details": SellerDetails,
    GetDirectionBtn
  },
  data() {
    return {
      item: {} as Item
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    destination() {
      //@ts-ignore
      const longitude = this.item.location.coordinates[1];
      console.log(longitude);
      //@ts-ignore
      const latitude = this.item.location.coordinates[0];
      return {
        lat: latitude,
        lng: longitude
      };
    },
    isMobile() {
      return !this.$vmq.lg;
    }
  },
  methods: {
    getItemDetails() {
      http
        .get(`/items/${this.itemId}`)
        .then(response => {
          this.item = response.data.item;
          console.log(this.item);
        })
        .catch(error => console.error(error));
    },
    bcumItems() {
      return [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Items",
          href: "#"
        },
        {
          text: this.item.title,
          active: true
        }
      ];
    }
  },
  created() {
    this.getItemDetails();
  }
});
